.collections-container {
    margin-top: $sp84;
    .banner {
        height: calc(100vh - $sp84);
        width: calc(100vw - 17px);
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .content {
            position: absolute;
            width: 100%;
            bottom: 100px;
            left: 0;

            .content-container {
                max-width: 1200px;
                margin: 0 auto;

                .title {
                    font-size: $sp52;
                    color: white;
                    margin-bottom: $sp16;

                }

                .breadcrumb {
                    display: flex;

                    .home {
                        cursor: pointer;
                    }

                    p {
                        color: white;
                    }
                }
            }
        }

        .blur {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.45);;
        }
    }

    .gallerys {
        margin: $sp112 $sp12;
        .categorys {
            display: flex;
            justify-content: center;

            button {
                border: 1px solid #d5d5d5;
                margin: 0 $sp24;
                padding: $sp12 $sp36;
                color: $gray500;
                font-weight: 700;
            }

            .selected {
                color: $brownYellow;
                border: 1px solid $brownYellow;
            }
        }

        .gallery {
            display: flex;
            flex-wrap: wrap;
            max-width: 1200px;
            width: 100%;
            margin: $sp60 auto;
            justify-content: space-between;

            .item-content {
                flex-basis: 30%;
                display: flex;
                flex-direction: column;

                .item-container {
                    height: fit-content;
                    padding-bottom: $sp60;

                    .pic {
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .content {
                        padding-top: $sp28;
                        .title-img {
                            font-size: $sp24;
                            color: $blackGrey;
                        }
        
                        .description {
                            color: $gray500;
                        }
                    }
                }
            }

            button {
                background-color: $brownYellow;
                font-family: Rale-Way;
                
            }
            }
    }

    .gallerys-mobile {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .collections-container {
        margin-top: $sp48;
        .banner {
            width: 100%;
            height: calc(45vh - $sp84);
            .content {
                .content-container {
                    padding-left: $sp32;
                    .title {
                        font-size: $sp32;
                    }
                }
            }
        }

        .gallerys {
            display: none;
        }

        .gallerys-mobile {
            display: flex;
            flex-direction: column;

            .categorys {
                display: flex;
                justify-content: center;
                padding: $sp32 $sp20;
    
                button {
                    border: 1px solid #d5d5d5;
                    padding: $sp12 $sp20;
                    color: $gray500;
                    font-weight: 700;
                    border-radius: 0;
                    width: 100%;
                }
    
                .selected {
                    color: $brownYellow;
                    border: 1px solid $brownYellow;
                }

                .swiper {
                    width: 90vw;
                    height: 100%;
                  }
    
                  .swiper-wrapper {
                    width: 100% !important;
    
                      .swiper-slide {
                        text-align: center;
                        font-size: 16px;
                        background: #fff;
                        // width: 80% !important;
                      
                        /* Center slide text vertically */
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        align-items: center;
                      }
                  }
            }

            .gallery {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                width: 100vw;
                // align-items: center;
                justify-content: center;
            }
              
        }
    }
}