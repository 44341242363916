// Variables
@import "./helpers/materials/variables";

// Styled
@import "./helpers/materials/styled";

//swiper
@import "swiper/css";

// Components
@import "./App.scss";
@import "./views/auth/login/login-style.scss";
@import "./components/component.scss";
@import "./views/home/home.scss";
@import "./views/collections/collections.scss";
@import "./views/events/events.scss";
@import "./views/about-us/about-us.scss";
@import "./views/vr-tour/vr-tour-style.scss";
@import "./views/collection-detail/collection-detail.scss";
@import "./assets/css/ckeditor.css";
@font-face {
  font-family: Roboto-Mono;
  src: url("./assets/fonts/RobotoMono-Regular.ttf");
}
@font-face {
  font-family: Rale-Way;
  src: url("./assets/fonts/Raleway-Regular.ttf");
}
