// Colors
$gray200: #FAFAFA;
$gray300: #EFEFEF;
$white: #ffffff;
$black: #000000;
$blue: #1a67dc;
$blue200: #c8deff;
$lightGray: #F3F5F7;
$greenDark: #004029;
$greenLight: #83B341;
$red: #d32f2f;
$mediumGray: #C2CFCA;
$blackGrey: #252525;
$brownYellow: #A77D00;
$gray500: #5B5B5B;
$blue100: #F0F6FE;

// Font Size
$fontS: 12px;
$fontM: 16px;
$fontMP: 20px;
$fontL: 24px;
$fontXL: 36px;
$fontXXL: 48px;

// Font weight
$light: 300;
$regular: 400;
$semiBold: 600;
$bold: 900;

// Spacing
$sp4: 4px;
$sp8: 8px;
$sp12: 12px;
$sp16: 16px;
$sp20: 20px;
$sp24: 24px;
$sp28: 28px;
$sp32: 32px;
$sp36: 36px;
$sp40: 40px;
$sp44: 44px;
$sp48: 48px;
$sp52: 52px;
$sp56: 56px;
$sp60: 60px;
$sp80: 80px;
$sp84: 84px;
$sp96: 96px;
$sp112: 112px;

// Alignments
%verticalCenter {
  display: flex;
  align-items: center;
}

// Text
%textEllipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
