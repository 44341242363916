.events-container {
  margin-top: $sp84;
  .banner {
    height: calc(100vh - $sp84);
    width: calc(100vw - 17px);
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .content {
      position: absolute;
      width: 100%;
      bottom: 100px;
      left: 0;

      .content-container {
        max-width: 1200px;
        margin: 0 auto;

        .title {
          font-size: $sp52;
          color: white;
          margin-bottom: $sp16;
        }

        .breadcrumb {
          display: flex;

          .home {
            cursor: pointer;
          }

          p {
            color: white;
          }
        }
      }
    }

    .blur {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.45);;
    }
  }

  .content {
    margin: $sp112 0;

    .current-event {
      .title {
        display: flex;
        justify-content: center;
        font-size: $sp52;
        font-weight: 600;
        text-transform: uppercase;
      }

      .event {
        display: flex;
        align-items: center;
        margin: $sp112 0;

        .event-time {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .title-event {
            font-size: $sp24;
            color: $blackGrey;
            padding-bottom: $sp32;
            font-weight: 600;
            text-align: justify;
          }
          .date {
            color: $gray500;
          }
          .place {
            color: $gray500;
          }
        }

        .swiper {
          width: 88vw;
          height: 100%;
          margin-left: $sp36;

          .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;
            // width: 100% !important;

            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;

            .event-swiper {
              width: 100%;

              img {
                width: 100%;
              }
            }

            
          }

          .swiper-pagination {
            .swiper-pagination-bullet-active {
              background: $blackGrey;
            }
          }
        }
      }

      .event-container {
        margin: $sp112 3%;

        .event-current {

          .event-swiper {
            width: 100%;

            img {
              width: 100%;
            }
          }

          .carousel-component-container {
            .carousel-component {
              .carousel-image {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;

                .carousel-text {
                  text-align: center;
                  z-index: 1;

                  @keyframes top-first {
                    from {
                      transform: scale(0);
                      opacity: 0;
                    }
                    to {
                      transform: scale(1);
                      opacity: 1;
                    }
                  }

                  .top-text-first {
                    animation: top-first 1s ease-in;
                  }

                  &.carousel-text-bottom {
                    position: absolute;
                    bottom: $sp80;
                    right: $sp60;

                    p {
                      font-size: 41px !important;
                    }
                  }
                }

                img {
                  position: absolute;
                  z-index: -1;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }

              .carousel-image::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.48);
                width: 100%;
                height: 100%;
              }

              .carousel-indicator {
                position: absolute;
                bottom: $sp40;
                z-index: 1;
              }

              .grid-event{
                display: flex;
              }
            }
          }
        }

        .event-time {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          justify-content: center;
          height: 100%;

          .title-event {
            font-size: $sp24;
            color: $blackGrey;
            padding-bottom: $sp32;
            font-weight: 600;
            text-align: justify;
          }
          .date {
            color: $gray500;
          }
          .place {
            color: $gray500;
          }
        }
      }
    }

    .upcoming {
      margin: $sp112 auto;
      max-width: 1200px;

      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: $sp80;
        align-items: flex-end;
        padding: 0 $sp12;

        .left {
          font-size: $sp52;
          font-weight: 600;
        }

        .right {
          color: $gray500;
        }
      }
    }

    .past-event {
      margin: $sp112 0;
    }

    .all-event {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $sp40;

      .event {
        position: relative;
        height: 500px;

        .img {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .description {
          position: absolute;
          bottom: 10%;
          left: 10%;
          z-index: 2;

          p {
            color: white;
          }

          button {
            padding: $sp12 $sp32;
            background-color: $brownYellow;
            color: white;
            box-shadow: none;
            border-radius: 0;
            font-family: Rale-Way;
          }

          .title-event {
            font-size: $sp20;
            margin-bottom: $sp12;
            text-transform: uppercase;
            font-weight: 600;
          }

          .time {
            display: flex;
            align-items: center;
            margin-bottom: $sp24;
            .MuiSvgIcon-root {
              width: 8px;
              height: 8px;
            }
          }
        }

        .blur {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(
            180deg,
            rgba(3, 3, 3, 0.52) 36.38%,
            #000000 100%
          );
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .events-container {
    margin-top: $sp48;
    .content {
      margin: $sp52 0 !important;
    }
    .banner {
      width: 100%;
      height: calc(45vh - $sp84);
      .content {
          .content-container {
              padding-left: $sp32;
              .title {
                  font-size: $sp32;
              }
          }
      }
    }

    .upcoming {
      margin: $sp52 0 !important;
      .title {
        flex-direction: column;
        padding: $sp20;

        .left {
          margin-bottom: $sp28;
          font-size: $sp32 !important;
        }
      }

      .all-event {
        grid-template-columns: repeat(1, 1fr) !important;
      }
    }

    .current-event {
      .title {
        font-size: $sp32 !important;
      }

      .event-container {
        margin: $sp52 0 !important;

        .event-time {
          margin-top: 0 !important;
        }
      }
    }

    .past-event {
      margin: $sp52 0 !important;
      .title {
        align-items: center !important;
        padding: $sp16;
        margin-bottom: $sp52 !important;
        p {

          text-transform: uppercase;
        }
      }
    }

    .all-event {
      .event {
        height: 300px !important;
      }
    }
  }
}
