html,
body {
  min-height: 100vh;
  margin: 0px;
  display: flex;
  flex: 1;
  font-family: Rale-Way;
  overflow: auto !important;
  padding: 0 !important;

  p {
    color: $blackGrey;
  }
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.app-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: $black;
  z-index: 99999;
  opacity: 0.5;
}

.back-to-top {
  position: fixed;
  bottom: 2vh;
  right: 2vh;
  z-index: 9;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  .MuiButtonBase-root {
    background-color: #A77D00;
  }
  .MuiButtonBase-root:hover {
    background-color: #e0a902;
  }
}

// Component Styles
.customTextField {
  .MuiInputBase-root {
    height: 56px;
  }

  .iconTextField {
    height: 24px;
    width: 24px;
  }
}
