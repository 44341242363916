.flag-icon {
    height: 28px;
    width: 28px;
  
    img {
      height: 100%;
      width: 100% !important;
      object-fit: cover;
    }
  }

.header-bar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;

    .header-container {
        background-color: white;
        width: 100%;

        .content {
            max-width: 1200px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: $sp16 0;

            .logo {
                height: $sp52;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .nav {
                display: flex;
                justify-content: space-between;
                align-items: center;

                a {
                    text-decoration: none;
                    color: $blackGrey;
                    padding: $sp4 $sp20;
                }

                .selected {
                    color: $brownYellow;
                    border-bottom: 2px solid $brownYellow;
                }

                .search {
                    .search-input {
                        padding-left: $sp32;
    
                        .MuiInput-root {
                            &::before {
                                border-bottom: 2px solid $brownYellow;
                            }
                            &::after {
                                border-bottom: 2px solid $brownYellow;
                            }
                        }
                    }

                    .collection {
                        margin: $sp20 $sp32;
                        border-bottom: 2px solid #DFDFDF;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                    }
    
                    .MuiCollapse-root {
                        width: 20%;

                        .MuiCollapse-wrapper {
                            overflow: auto;
                            height: 50vh ;
                        }
                    }
                }

                .MuiCollapse-root {
                    position: absolute;
                    background-color: white;
                    border: 1px solid $brownYellow;

                    .event-nav {
                        text-decoration: none;
                        padding: $sp12 $sp20;
                        text-transform: capitalize;
                    }
                }
            }
        }

        .language-select {
            margin-left: $sp16;
            margin-right: -12px;
    
            .MuiSelect-select {
              padding-top: 0;
              padding-bottom: 0;
              padding-left: 0;
              border-radius: 0;
            }
    
            svg {
              color: $white;
            }
    
            fieldset {
              border: none;
              border-radius: 0;
            }
    
    
        }
    }

    .header-container-mobile {
        display: none;
        background-color: white;
        width: 100%;

        .search {
            .search-input {
                .MuiInput-root {
                    &::before {
                        border-bottom: 2px solid $brownYellow;
                    }
                    &::after {
                        border-bottom: 2px solid $brownYellow;
                    }
                }
            }

            .MuiFormControl-root {
                width: 100%;


            }

            .collection {
                margin: $sp20 0 $sp20 $sp32;
                padding-bottom: $sp16;
                border-bottom: 2px solid #DFDFDF;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
            }

            .MuiCollapse-root {
                width: 100%;

                .MuiCollapse-wrapper {
                    overflow: auto;
                    height: 50vh ;
                }
            }
        }
    }
}

.footer {
    border-top: 1px solid #DFDFDF;
width: 100%;

    .footer-container {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding-top: $sp112;
    
        .content {
            display: flex;
            justify-content: space-between;
            padding-bottom: $sp112;
            border-bottom: 1px solid #DFDFDF;
    
            .logo {
                height: $sp52;
                img {
                    height: 100%;
                    width: 100%;
                }
            }
    
            .content-list {
                display: flex;
                justify-content: space-between;
                width: 60%;
    
                .item {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
    
                    p {
                        margin-bottom: $sp20;
                    }

                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    
        .contact-info {
            display: flex;
            justify-content: space-evenly;
            padding: $sp12 0;
            align-items: center;
    
            .icon {
                display: flex;
                align-items: center;
    
                svg {
                    height: $sp28;
                    padding-right: $sp12;
                }
            }
        }
    }
}

.item-container {
    width: 100%;

    .pic {
        width: 100%; 

        img {
            height: 100%;
            width: 100%;
        }
    }
}

@media screen and (max-width: 425px) {
    .header-bar {
        .header-container {
            display: none;
        }

        .header-container-mobile {
            display: flex;
            width: 100%;
            justify-content: space-between;
            .logo {
                height: 32px;
                width: 100px;
                padding: $sp8 $sp20;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .menu {
                position: relative;
                display: flex;
                justify-content: flex-end;
                .nav-mobile {
                    position: absolute;
                    top: $sp48;
                    background: white;
                    height: 100vh !important;
                    width: 100vw !important;
                    .nav {
                        display: flex;
                        flex-direction: column;
                        padding: 0 $sp20;
                        a {
                            text-decoration: none;
                            color: $blackGrey;
                            padding: $sp16 0;
                            border-bottom: 1px solid #DFDFDF;
                        }

                        .event-item {
                            cursor: pointer;
                            border-bottom: 1px solid #DFDFDF;
                            padding: $sp16 0;
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                            align-items: center;

                            button {
                                padding: 0;
                            }
                            
                            a {
                                border: none;
                            }
                        }

                        .event-nav {
                            margin-left: $sp40;
                        }
                    }

                    .MuiCollapse-wrapperInner {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .footer {
        .footer-container {
            padding-top: $sp52;
            .content {
                display: flex;
                flex-direction: column;
                .logo {
                    width: 176px;
                    padding: 0 $sp16;
                }
                .content-list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: $sp20;
                    width: 100vw;

                    .item {
                    padding: $sp12 $sp12;

                    }
                }
            }

            .contact-info {
               flex-direction: column;
               justify-content: flex-start;
               align-items: flex-start;
               padding: $sp12;
            }
        }
    }

    .item-container {
        display: flex;
        flex-direction: column;
        width: 100vw;
        align-items: center;
        margin-bottom: $sp60;

        .pic {
            width: 90%;
            margin-bottom: $sp28;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
