.home-container {
  margin-top: $sp84;

  .banner {
    position: relative;
    .video-swiper {
      height: calc(100vh - $sp112);
      width: calc(100vw - 17px);

      .swiper {
        height: 100%;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .navigator-img {
        display: flex;
        position: absolute;
        top: 80%;
        right: 10%;
        z-index: 12;

        .review-swiper-button-next,
        .review-swiper-button-prev {
          padding: $sp8;
          background-color: $mediumGray;
          height: $sp20;
          width: $sp20;
          border-radius: $sp32;
          display: flex;
          margin: $sp12;

          img {
            height: 100%;
            width: 100%;
            color: white;
          }
        }

        .paginator-video {
          color: white;
          display: flex;
          align-items: center;

          p {
            color: white;
            font-size: $sp20;
          }
        }
      }
    }

    .blur {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.45);
      z-index: 10;
    }

    .content {
      position: absolute;
      top: 20%;
      left: 8%;
      width: 50%;
      z-index: 10;

      .title {
        font-size: $sp52;
        color: white;
      }

      p {
        color: white;
        margin-top: $sp60;
      }

      button {
        margin-top: $sp32;
        background-color: $brownYellow;
        font-family: Rale-Way;
        border-radius: 0 !important;
        box-shadow: none;
        text-transform: none;
        padding: $sp12 $sp36;
      }
    }

    .social-link {
      display: flex;
      position: absolute;
      left: 8%;
      top: 80%;
      align-items: center;
      z-index: 10;

      .line {
        height: 1px;
        width: $sp12;
        border-bottom: 1px solid white;
        padding: 0 $sp12;
        margin-left: $sp12;
      }

      img {
        width: $sp16;
        height: $sp16;
        padding: $sp8;
      }
    }
  }

  .welcome {
    max-width: 1200px;
    width: 100%;
    margin: $sp112 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      padding-right: $sp96;
      .title {
        font-size: 38px;
        color: $blackGrey;
        text-transform: uppercase;
        text-align: left;
        margin-bottom: $sp44;
        font-weight: 600;
        font-style: normal;
        line-height: 69px;
      }

      p {
        text-align: justify;
        color: $gray500;
      }
    }

    .right {
      img {
        width: 550px;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .artwork {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    .title {
      margin: $sp112 0;
      display: flex;
      justify-content: space-between;

      .left {
        color: $blackGrey;
        font-size: 38px;
        font-weight: 600;
        text-align: left;
      }

      .right {
        font-size: $sp24;
        color: $gray500;
        width: 60%;
        text-align: right;
      }
    }

    .artwork-swiper {
      width: 100%;
      margin-bottom: $sp112;

      .swiper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;

          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
        }
      }
      .image-slide {
        height: 750px;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .navigator-img {
        display: flex;
        width: 100%;
        justify-content: end;
        .review-swiper-button-next {
          width: $sp32;
          cursor: pointer;
        }

        .review-swiper-button-prev {
          width: $sp32;
          padding-right: $sp12;
          cursor: pointer;
        }
      }
    }
  }

  .collections {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: $sp52;

      .left {
        .title-left {
          font-size: 38px;
          font-weight: 600;
        }

        .text-left {
          color: $blackGrey;
        }
      }

      .right {
        display: flex;
        align-items: center;

        p {
          padding-right: $sp16;
        }

        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }

    .gallery {
      display: flex;
      flex-wrap: wrap;
      margin-top: $sp56;
      justify-content: space-between;

      .item-content {
        flex-basis: 30%;
        display: flex;
        flex-direction: column;

        .item-container {
          height: fit-content;
          padding-bottom: $sp60;

          .pic {
            img {
              width: 100%;
              height: 100%;
            }
          }
          .content {
            padding-top: $sp28;
            .title-img {
              font-size: $sp24;
              color: $blackGrey;
            }

            .description {
              color: $gray500;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    button {
      background-color: $brownYellow;
      border-radius: 0 !important;
      text-transform: capitalize;
      font-family: Rale-Way;
    }
  }

  .events {
    margin: 200px 0 200px 0;

    .title {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: $sp112;
      .top {
        color: $gray500;
      }
      .bottom {
        font-size: 38px;
        color: $blackGrey;
      }
    }

    .content {
      display: flex;
      align-items: center;
      width: 100%;

      .event-swiper {
        height: 500px;

        img {
          height: 100%;
        }
      }

      .event-time {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .title-event {
          font-size: $sp24;
          color: $blackGrey;
          padding-bottom: $sp32;
          font-weight: 600;
        }
        .date {
          color: $gray500;
        }
        .place {
          color: $gray500;
        }
      }

      .swiper {
        width: 50vw;
        height: 100%;
        margin-left: $sp36;

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
          // width: 100% !important;

          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;

          .event-swiper {
            width: 100%;

            img {
              width: 100%;
            }
          }
        }

        .swiper-pagination {
          .swiper-pagination-bullet-active {
            background: $blackGrey;
          }
        }
      }
    }
  }

  .all-events {
    margin-bottom: 200px;
    .title-container {
      max-width: 1200px;
      margin: $sp112 auto;
      width: 100%;

      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left {
          p {
            font-size: 38px;
            color: $blackGrey;
          }
        }
        .right {
          display: flex;
          align-items: center;
          p {
            padding-right: $sp12;
          }

          .btn-next {
            cursor: pointer;
          }
        }
      }
    }

    .all-event-swiper {
      padding-left: 15vw;
      .swiper {
        width: 84.1vw;
        height: 100%;

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;

          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;

          .item {
            display: flex;
            flex-direction: column;
            height: 100%;

            .item-img {
              width: 100%;
              height: 416px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              align-items: center;
              .title-event {
                font-size: $sp24;
                font-weight: 600;
                color: $blackGrey;
                margin: $sp32 0;
                font-weight: 600;
              }
              .date {
                color: $gray500;
              }
              .place {
                color: $gray500;
              }
            }
          }
        }
      }
    }
  }

  .contact {
    padding: 100px 0;
    background-color: $blue100;
    .css-1hw9j7s.Mui-disabled {
      color: #fff !important;
      background-color: #806003 !important;
    }
    .contact-container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: $sp52;
        font-weight: 600;
        color: $blackGrey;
      }

      .email {
        display: flex;
        width: 50%;
        margin-top: $sp48;

        .contact-subscribe {
          width: 100%;
          padding-right: $sp20;

          .MuiFormControl-root {
            width: 100%;
            height: 100%;

            .MuiInput-root {
              height: 100%;

              &::before {
                height: 100%;
                border: 1px solid #d1d1d1;
              }

              &::after {
                border: none;
              }

              input {
                padding: $sp12 $sp20;
              }
            }
          }
        }

        button {
          background-color: $brownYellow;
          border-radius: 0 !important;
          padding: $sp20 $sp48;
          text-transform: capitalize;
          font-size: Rale-Way;
          box-shadow: none !important;
          box-sizing: unset;
        }
      }
    }
  }
  .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: #fff;
    background-color: #806003 !important;
  }
}
.pt8 {
  padding-top: 8px;
}
.helper-text {
  color: red !important;
  font-size: 12px !important;
}
.collections-mobile {
  display: none;
}
.collection-destop {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
@media only screen and (max-width: 1280px) {
  .home-container .banner .content {
    top: 0px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .home-container .banner .content {
    top: 0 !important;
  }
  .home-container .banner .content {
    top: 0px !important;
    left: 2% !important;
    width: 82% !important;
    .title {
      font-size: 32px !important;
    }
  }
  .home-container .banner .video-swiper {
    width: 100vw !important;
  }
  .home-container .banner .social-link {
    bottom: 20px !important;
    left: 2% !important;
  }
  .home-container .banner .video-swiper .navigator-img {
    top: 89% !important;
    right: 20% !important;
  }
  .home-container .welcome .left {
    padding-left: 96px !important;
  }
  .home-container .welcome {
    display: block !important;
    .right {
      display: flex;
      justify-content: center;
    }
  }
  .artwork {
    width: 100vw !important;
  }
  .home-container .all-events .all-event-swiper {
    padding-left: 0vw !important;
  }
  .home-container .all-events .all-event-swiper .swiper {
    width: 100vw !important;
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .home-container .contact .contact-container .title {
    text-align: center !important;
  }
}
@media only screen and (max-width: 450px) {
  .home-container {
    margin-top: 48px !important;
    width: fit-content !important;
    .contact .contact-container .title {
      font-size: 32px !important;
      text-align: center !important;
    }
    .contact {
      padding: 50px 0px !important;
      .title-2 {
        text-align: center;
        padding: 20px 12px;
      }
      .contact-container .email {
        display: block !important;
        width: 100% !important;
        .contact-subscribe {
          padding-right: 0px !important;
          width: 90% !important;
          margin: auto !important;
          .MuiFormControl-root .MuiInput-root input {
            padding: 18px 20px;
          }
        }

        button {
          width: 100% !important;
          box-sizing: revert;
        }
        .button-email {
          width: 90%;
          margin: auto;
          padding-top: 20px;
        }
      }
    }
    .welcome {
      width: min-content !important;
      display: block !important;
      width: 100vw !important;
      height: fit-content !important;
      margin-top: 50px !important;
      margin-bottom: 50px !important;
      .left .title {
        font-size: 32px !important;
        font-weight: 500 !important;
        line-height: 42px !important;
      }
      .left {
        padding: 0px 20px !important;
        margin-bottom: 50px !important;
      }
      .right img {
        width: 100% !important;
      }
    }
    .artwork .title {
      display: block !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      margin: 50px 0px !important;
    }
    .artwork .title .right {
      text-align: left !important;
      width: 100%;
    }
    .artwork .artwork-swiper .image-slide {
      height: 300px !important;
    }
    .collections .title {
      display: block !important;
      padding-left: 20px !important;
    }
    .collections .title .left .title-left {
      font-size: 32px !important;
    }
    .events .title .bottom {
      text-align: center;
      font-size: 32px !important;
    }
    .events .content {
      display: block !important;
      .event-time .title-event {
        padding-top: 28px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        text-align: center;
      }
    }
    .events .content .swiper {
      width: 100% !important;
      margin-left: 0px !important;
      .event-swiper {
        height: 300px;
      }
    }
    .events {
      margin: 100px 0px !important;
      .title {
        margin-bottom: 50px !important;
      }
    }
    .all-events .title-container .title {
      display: block !important;
      // padding: 0px 20px !important;

      .left p {
        font-size: 32px !important;
        padding-left: 20px !important;
      }
      .right {
        margin-top: 28px !important;
        p {
          padding-left: 20px !important;
        }
      }
    }
    .all-events .title-container {
      margin-bottom: 50px !important;
    }
    .all-events .all-event-swiper .swiper .swiper-slide .item .item-img {
      height: 300px !important;
    }
    .all-events .all-event-swiper .swiper {
      width: 100vw !important;
    }
    .all-events .all-event-swiper {
      padding-left: 0px !important;
      .swiper-wrapper {
        height: 500px !important;
      }
    }
    .all-events {
      margin-bottom: 0px !important;
    }
  }
  .collection-destop {
    display: none !important;
    width: 100% !important;
  }
  .collections-mobile {
    display: block !important;
    width: 100% !important;
    .item-container {
      width: 90% !important;
      margin: auto;
      height: fit-content;
      .pic {
        padding-top: 60px !important;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .content {
        padding-top: $sp28;
        .title-img {
          font-size: $sp24;
          color: $blackGrey;
        }

        .description {
          color: $gray500;
        }
      }
    }
    .gallery {
      display: flex;
      flex-wrap: wrap;
      margin-top: $sp56;
      justify-content: space-between;

      .item-content {
        flex-basis: 30%;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .home-container .banner .content {
    top: 0px !important;
    left: 2% !important;
    width: 82% !important;
    .title {
      font-size: 32px !important;
    }
  }
  .home-container .banner .video-swiper {
    width: 100vw !important;
  }
  .home-container .banner .social-link {
    bottom: 20px !important;
    left: 2% !important;
  }
  .home-container .banner .video-swiper .navigator-img {
    top: 91% !important;
    left: 60% !important;
    right: 0 !important;
  }
  // .banner .blur {
  //   width: 0% !important;
  // }
  .home-container .swiper-slide {
    width: 100% !important;
  }
  .small-title {
    display: none;
  }
}
@media screen and (max-width: 326px) {
  .home-container {
    .banner {
      .video-swiper {
        .navigator-img {
          left: 50% !important;
        }
      }
    }
  }
}
