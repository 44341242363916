.detail-container {
    max-width: 1200px;
    width: 100%;
    margin: 84px auto;

    .breadcrumb {
        display: flex;
        margin-top: $sp36;

        a {
            text-decoration: none;
            color: black;
        }
    }

    .content {
        margin: $sp52 0;
        display: flex;

        .left {
            display: flex;
            flex-direction: column;
            padding-right: $sp28;
            width: 60%;

            .pic {
                margin-bottom: $sp20;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .des {
                p{
                    text-align: justify;
                    color: $gray500;
                }
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            padding: $sp36;
            background-color: $gray200;
            .info {
                width: 100%;
                .name {
                    font-size: $sp24;
                    font-weight: 600;
                    padding-bottom: $sp28;
                    border-bottom: 1px solid #d1d1d1;
                    margin-bottom: $sp28;
                }
    
                .short-des {
                    margin-bottom: $sp28;
                    p {
                        color: $gray500;
                    }
                }
    
                .date {
                    p {
                        color: $blackGrey; 
                    }
                }
    
                .dimensions {
                    p {
                        color: $blackGrey; 
                    }
                }
            }

            .share {
                display: flex;
                padding: $sp52 $sp32;
                border-top: 1px solid #d1d1d1;
                align-items: center;
                justify-content: space-evenly;
            }
        }
    }

    .categories {
        border-top: 1px solid #d1d1d1;
        padding: $sp112 0;
        .title {
            p {
                font-size: $sp52;
                font-weight: 600;
            }
        }

        .list {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(3, 1fr);
            gap: $sp20;

            .item {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .detail-container {
        .content {
            padding: 0 $sp20;
            .left {
                .pic {
                    width: 50vw;
                }
            }
        }

        .categories {
            padding: 0 $sp20;
        }
    }
}

@media screen and (max-width: 425px) {
    .detail-container {
        margin: $sp40 auto;
        .breadcrumb {
            padding-left: $sp20;
            margin: $sp12 0;
        }
        .content {
            flex-direction: column;
            align-items: center;
            margin:  0;
            .left {
                width: auto;
                padding: 0;
                .pic {
                    width: 90vw;
                }
            }

            .right {
                width: 80%;
                margin: $sp20;
                padding: $sp20;
            }
        }

        .categories {
            padding: $sp28 $sp20;
            .title {
                padding: $sp12 $sp20;
                p {
                    font-size: $sp32;
                }
            }

            .list {
                grid-template-columns: repeat(1, 1fr);

                .item {
                    margin-bottom: $sp20;
                }
            }
        }
    }
}