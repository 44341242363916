
.about-us-container {
    margin-top: $sp84;
    .banner {
        height: calc(100vh - $sp84);
        width: calc(100vw - 17px);
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }

        .content {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            justify-content: center;

            .content-container {
                display: flex;
                width: 50%;
                height: 100%;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .title {
                    font-size: $sp52;
                    color: white;
                    margin-bottom: $sp16;
                }

                .banner-content {
                    color: white;
                    text-align: justify;
                }

            }
        }

        .blur {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.45);;
        }
    }

    .about-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: $sp112 auto;
        max-width: 1200px;
        width: 100%;
        padding: 0 $sp12;

        .left {
            padding-right: $sp40;
            padding-left: $sp12;
            .title {
                font-size: $sp52;
                font-weight: 500;
                margin-bottom: $sp20;
            }

            p {
                text-align: justify;
            }
        }

        .right {
            padding-right: $sp12;
        }

    }
}

@media screen and (max-width: 425px) {
    .about-us-container {
        margin-top: $sp48;

        .banner {
            width: 100vw;
            height: calc(45vh - $sp84);

            .content {
                .content-container {
                    width: 90%;
                    .title {
                        font-size: $sp32;
                    }
                }
            }
        }

        .about-content {
            flex-direction: column;
            width: 100vw;
            padding: 0;
            .left {
                padding-left: $sp40;
                padding-bottom: $sp40;
                .title {
                    font-size: $sp32;
                    text-transform: uppercase;
                    margin-bottom: $sp48;
                }
            }

            .right {
                width: 100vw;
                padding: 0;

                img {
                    width: 100%;
                }
            }
        }
    }
}